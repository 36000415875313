import React, {FC} from 'react';
import {IStyle} from './Style';

const TikTok: FC<IStyle> = ({color}) => {
    //fill="#527BAA"
    return (
        <a href={"https://tiktok.com/@mysta1es"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill={color}>
                <path
                    d="M25.4 8.73C24.3746 7.55943 23.8096 6.05615 23.81 4.5H19.175V23.1C19.1393 24.1065 18.7143 25.0599 17.9897 25.7594C17.265 26.4589 16.2972 26.8499 15.29 26.85C13.16 26.85 11.39 25.11 11.39 22.95C11.39 20.37 13.88 18.435 16.445 19.23V14.49C11.27 13.8 6.74001 17.82 6.74001 22.95C6.74001 27.945 10.88 31.5 15.275 31.5C19.985 31.5 23.81 27.675 23.81 22.95V13.515C25.6895 14.8648 27.9461 15.589 30.26 15.585V10.95C30.26 10.95 27.44 11.085 25.4 8.73Z"/>
            </svg>
        </a>
    );
};

export default TikTok;
import Reactб from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Stories from "../../pages/Stories";
import Images from "../../pages/Images";
import News from "../../pages/News";
import cs from './Content.module.css';

const Content = () => {
    return (
        <div className={cs.content}>
            <Routes>
                <Route path='/stories' element={<Stories/> }/>
                <Route path='/images'  element={<Images/>}/>
                <Route path='/news'  element={<News/>}/>
                <Route path="*" element={<Navigate to="/stories" replace/>} />
            </Routes>
        </div>
    );
};

export default Content;
import React, {useRef} from 'react';
import cs from './Menu.module.css';
import YouTube from "../ui/YouTube";
import TikTok from "../ui/TikTok";
import Twitter from "../ui/Twitter";
import FaceBook from "../ui/FaceBook";
import InstaGram from "../ui/InstaGram";
// import Patreon from "../ui/Patreon";
import Burger from './Burger';

const Menu = () => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} className={cs.menu}>
            <Burger parent={ref}/>
            <div className={cs.media}>
                <YouTube color={"#527BAA"}/>
                <TikTok color={"#527BAA"}/>
                <Twitter color={"#527BAA"}/>
                <FaceBook color={"#527BAA"}/>
                <InstaGram color={"#527BAA"}/>
            </div>
            {/*<div className={cs.donate}><Patreon color={"#3b3d3d"}/></div>*/}
        </div>
    );
};

export default Menu;
import React from 'react';
import cs from './Header.module.css'

const Header = () => {
    return (
        <div className={cs.header}>
            <div className={cs.girl}/>
            <div className={cs.title}>
                <div className={cs.bird}/>
                <div className={cs.logo}/>
                <div className={cs.text}>Live comics of mystic, humour and romance</div>
            </div>
            <div className={cs.boy} />
        </div>
    );
};

export default Header;
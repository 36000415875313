import React from 'react';
import cs from './Footer.module.css'
import YouTube from "../ui/YouTube";
import TikTok from "../ui/TikTok";
import Twitter from "../ui/Twitter";
import FaceBook from "../ui/FaceBook";
import InstaGram from "../ui/InstaGram";
// import Patreon from "../ui/Patreon";

const Footer = () => {
    return (
        <div className={cs.footer}>
            <div className={cs.info}>
                <div className={cs.logo} onClick={e => window.scrollTo({top: 0, behavior: 'smooth'})}/>
                <div className={cs.media}>
                    <YouTube color={"#09203C"}/>
                    <TikTok color={"#09203C"}/>
                    <Twitter color={"#09203C"}/>
                    <FaceBook color={"#09203C"}/>
                    <InstaGram color={"#09203C"}/>
                    {/*<Patreon color={"#09203C"}/>*/}
                </div>
                <div className={cs.mail}>info@mystales.com</div>
                <div className={cs.copyright}>© 2023  MysTales</div>
            </div>
        </div>
    );
};

export default Footer;
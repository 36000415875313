import React, {FC} from 'react';
import {IStyle} from './Style';

const Twitter: FC<IStyle> = ({color}) => {
    return (
        <a href={"https://twitter.com/@mysta1es"}>
            <svg width="45" height="36" viewBox="0 0 45 36" fill={color} xmlns="http://www.w3.org/2000/svg">
                <path d="M44.0957 4.38373C42.4445 5.11523 40.693 5.59538 38.8996 5.80823C40.7677 4.68861 42.202 2.91565 42.8774 0.803194C41.1016 1.85699 39.1587 2.59945 37.1327 2.99851C35.4824 1.24036 33.1314 0.141586 30.5292 0.141586C25.533 0.141586 21.4822 4.19219 21.4822 9.18809C21.4822 9.89724 21.5623 10.5876 21.7164 11.2499C14.1978 10.8725 7.53175 7.27096 3.06965 1.79759C2.29108 3.13372 1.84496 4.68792 1.84496 6.34564C1.84496 9.48436 3.44222 12.2533 5.86955 13.8757C4.43291 13.8307 3.02787 13.4427 1.77175 12.744C1.77123 12.7819 1.77123 12.8198 1.77123 12.8579C1.77123 17.2411 4.88963 20.8976 9.02808 21.7287C7.69588 22.091 6.2985 22.144 4.94268 21.8837C6.09382 25.4778 9.43493 28.0933 13.3936 28.1665C10.2974 30.5928 6.39646 32.0391 2.15811 32.0391C1.42777 32.0391 0.70777 31.9962 0 31.9127C4.00358 34.4796 8.75885 35.9773 13.8678 35.9773C30.5082 35.9773 39.6076 22.192 39.6076 10.2373C39.6076 9.84488 39.599 9.45474 39.5814 9.06683C41.3525 7.78648 42.8812 6.20064 44.0957 4.38373Z"/>
            </svg>
        </a>
    );
};

export default Twitter;